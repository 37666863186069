import { type HttpResponsePayload, createHttpEndpoint } from '../../utils'

/**
 * Create customer return Address
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Customer/operation/createCustomerReturnAddress}
 */

export interface PostBuyBackAddressPayload {
  id: number
  public_id: string
}

export type PostClientAddressResponse =
  HttpResponsePayload<PostBuyBackAddressPayload>

export const postBuyBackAddress = createHttpEndpoint<PostClientAddressResponse>(
  {
    method: 'POST',
    operationId: 'postBuyBackAddress',
    path: '/bm/buyback/v1/customer/address',
    transformRequestToSnakeCase: true,
  },
)
