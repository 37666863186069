import { defineStore } from 'pinia'

export type PhonelineSetupInformation = {
  offerOption: string
  iban: string
  phoneNumber: string | null
  rioCode: string | null
  childrenProtection: boolean
}

interface BouyguesStore {
  accessToken: string | null
  phonelineSetupInformation: PhonelineSetupInformation | null
}

export const useBouyguesStore = defineStore('bouygues', {
  state: (): BouyguesStore => ({
    accessToken: null,
    phonelineSetupInformation: null,
  }),

  actions: {
    setPhonelineSetupInformation(
      phonelineSetupInformation: PhonelineSetupInformation,
    ) {
      this.phonelineSetupInformation = phonelineSetupInformation
    },
    setAccessToken(accessToken: BouyguesStore['accessToken']) {
      this.accessToken = accessToken
    },
    clear() {
      // Doc: https://pinia.vuejs.org/core-concepts/state.html#resetting-the-state
      this.$reset()
    },
  },
})
